export default defineNuxtRouteMiddleware((to) => {
  const { $localePath } = useNuxtApp()

  if (to?.query?.token) {
    return navigateTo(
      $localePath({
        name: 'wishlist-token-shared',
        params: { token: to.query.token as string },
      }),
    )
  }
})
